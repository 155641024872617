/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}