.speech-bubble {
	position: relative;
	background: #e6e6e6;
    border-radius: .4em;
    padding: 5px;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	right: 0;
	top: 65%;
	width: 0;
	height: 0;
	border: 0.625em solid transparent;
	border-left-color: #e6e6e6;
	border-right: 0;
	border-bottom: 0;
	margin-right: -0.625em;
}

.panel{
	margin: auto;
	background-color: #ddd;
}

  .language-circle{
    width:20px;
    height:20px;
    border-radius:20px;
    font-size:12px;
    color:#fff;
    line-height:20px;
    text-align:center;
    background:#FF8349
}

  textarea {
	width: 100%;
	height: 90px;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #fff;
	font-size: 16px;
	resize: both;
  }
  
  #code {
	width: 100%;
	height: 100%;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #fff;
	font-size: 16px;
	resize: none;
  }