.hero-image {
  background-image: url("background.png");
  background-color: #fff;
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
  
.header-section{
  padding-top:130px;
  overflow: hidden;
}

.gridItem1{
  position: relative;
  padding-right: 3%;
  padding-left: 3%;
  margin-bottom: 1.5rem!important;  
}

.grid-item-margin{
  margin: auto;
}

.p1{
    display: block;
    text-transform: uppercase!important;
    font-size: 16px;
    color: #fff;
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
}

.p2{
    line-height: 1.8;
    font-size: 15px;
}
.title{
    font-size: 32px;
    font-weight: 500
}
