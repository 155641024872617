.search-results-found {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #FFFFFF;
}

.searchresultstable {
  width: "100%";
}

.search-table {
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
  margin: 30px 0;
  width: 100%;
}

.search-table tr {
  border-top: 1px solid #A7A7A7;
}

.search-table tr th {
  color: #000000;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: 15px;
  border-right: 1px solid #A7A7A7;
}

.search-table tr td {
  color: #000000;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-family: open sans;
  font-weight: 400;
  line-height: 15px;
  border-right: 1px solid #A7A7A7;
}
.search-table tr td  button{
  color: #fff !important;
  padding: 10px;
  font-size: 12px;
  background-color: #fba92c !important;
  box-shadow: none;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 15px;
  border-radius: 8px;
  text-transform: capitalize;
  width: 70px;
  height: 30px;
}