.pill {
  background-color: #e6e6e6;
  border-radius: 10px;
  color: #ff834f;
  margin-top: 3%;
  height: 8%;
}
.pill-text {
  padding: 5px;
  margin-left: 7%;
  display: inline-block;
}
html {
  font-size: 100%;
}